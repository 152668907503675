import {
  EMPLOYEE_FLOW_SLICE_NAME,
  TARGET_FLOW_SLICE_NAME,
  REPORT_FLOW_SLICE_NAME,
  NOTIFICATION_SLICE_NAME,
  LOADER_SLICE_NAME,
  BEAT_FLOW_SLICE_NAME,
  JMDO_FLOW_SLICE_NAME,
  RETAILER_MAPPING
} from "./sliceConstants";

import userReducer, { USER_FLOW_SLICE_NAME } from "./user/userSlice";
import employeeReducer from "./employee/employeeSlice";
import targetReducer from "./target/targetSlice";
import reportReducer from "./reports/reportSlice";
import notificationReducer from "./componentSlice/notificationSlice";
import loaderReducer from "./componentSlice/loader";
import beatReducer from "./beat/beatSlice";
import jmdoExtraReducers from "./jmdo/jmdoSlice";
import retailerMappingSlice from "./retailerMapping/retailerMappingSlice";

const allReducers = {
  [USER_FLOW_SLICE_NAME]: userReducer,
  [EMPLOYEE_FLOW_SLICE_NAME]: employeeReducer,
  [TARGET_FLOW_SLICE_NAME]: targetReducer,
  [NOTIFICATION_SLICE_NAME]: notificationReducer,
  [REPORT_FLOW_SLICE_NAME]: reportReducer,
  [LOADER_SLICE_NAME]: loaderReducer,
  [BEAT_FLOW_SLICE_NAME]: beatReducer,
  [JMDO_FLOW_SLICE_NAME]: jmdoExtraReducers,
  [RETAILER_MAPPING]: retailerMappingSlice,
};

export default allReducers;
