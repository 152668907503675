import { groupByKey } from "../../../../helper/utils";
import reportThunkActions from "./reportThunk";

function createExtraReducers() {
  return {
    ...fetchReportDetails(),
    ...fetchAchievementDetails(),
    ...fetchFocussedSku(),
    ...getTargetFilters(),
    ...fetchAchievementDetailsFilterWise(),
    ...fetchAchievementDetailsCategoryWise(),
    ...fetchIncentives(),
    ...getSubordinateFilter(),
    ...getSubordinates(),
  };

  function fetchReportDetails() {
    const { pending, fulfilled, rejected } =
      reportThunkActions.fetchReportDetails;
    return {
      [pending]: (state) => {
        state.fetchReportDetails.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.fetchReportDetails = {
          isLoading: false,
          error: null,
          data: JSON.parse(action?.payload)?.data || [],
        };
      },
      [rejected]: (state, action) => {
        state.fetchReportDetails = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
        };
      },
    };
  }
  function fetchAchievementDetails() {
    const { pending, fulfilled, rejected } =
      reportThunkActions.fetchAchievementDetails;
    return {
      [pending]: (state) => {
        state.fetchAchievementDetails.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.fetchAchievementDetails = {
          isLoading: false,
          error: null,
          data: JSON.parse(action?.payload)?.data || [],
        };
      },
      [rejected]: (state, action) => {
        state.fetchAchievementDetails = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }

  function fetchFocussedSku() {
    const { pending, fulfilled, rejected } =
      reportThunkActions.fetchFocussedSku;
    return {
      [pending]: (state) => {
        state.fetchFocussedSku.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.fetchFocussedSku = {
          isLoading: false,
          error: null,
          data: JSON.parse(action?.payload) || [],
        };
      },
      [rejected]: (state, action) => {
        state.fetchFocussedSku = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
        };
      },
    };
  }
  function getTargetFilters() {
    const { pending, fulfilled, rejected } =
      reportThunkActions.getTargetFilters;
    return {
      [pending]: (state) => {
        state.getTargetFilters.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.getTargetFilters = {
          isLoading: false,
          error: null,
          data: groupByKey(JSON.parse(action?.payload)) || [],
        };
      },
      [rejected]: (state, action) => {
        state.getTargetFilters = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }
  function fetchAchievementDetailsFilterWise() {
    const { pending, fulfilled, rejected } =
      reportThunkActions.fetchAchievementDetailsFilterWise;
    return {
      [pending]: (state) => {
        state.fetchAchievementDetailsFilterWise.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.fetchAchievementDetailsFilterWise = {
          isLoading: false,
          error: null,
          data: JSON.parse(action?.payload)?.data || [],
        };
      },
      [rejected]: (state, action) => {
        state.fetchAchievementDetailsFilterWise = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }
  function fetchAchievementDetailsCategoryWise() {
    const { pending, fulfilled, rejected } =
      reportThunkActions.fetchAchievementDetailsCategoryWise;
    return {
      [pending]: (state) => {
        state.fetchAchievementDetailsCategoryWise.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.fetchAchievementDetailsCategoryWise = {
          isLoading: false,
          error: null,
          data: JSON.parse(action?.payload)?.data || [],
        };
      },
      [rejected]: (state, action) => {
        state.fetchAchievementDetailsCategoryWise = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }

  function fetchIncentives() {
    const { pending, fulfilled, rejected } = reportThunkActions.fetchIncentives;
    return {
      [pending]: (state) => {
        state.fetchIncentives.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.fetchIncentives = {
          isLoading: false,
          error: null,
          data: JSON.parse(action?.payload)?.data || [],
        };
      },
      [rejected]: (state, action) => {
        state.fetchIncentives = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
        };
      },
    };
  }

  function getSubordinateFilter() {
    const { pending, fulfilled, rejected } =
      reportThunkActions.getSubordinateFilter;

    return {
      [pending]: (state) => {
        state.subordinates.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        const subOrdinateData = JSON.parse(action?.payload?.data)?.data;

        state.subordinates = {
          isLoading: false,
          error: null,
          data: subOrdinateData || [],
        };
      },
      [rejected]: (state, action) => {
        state.subordinates = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }

  function getSubordinates() {
    const { pending, fulfilled, rejected } = reportThunkActions.getSubordinates;

    return {
      [pending]: (state) => {
        state.subordinates.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        const subOrdinateData = JSON.parse(action?.payload?.data)?.data;

        state.subordinates = {
          isLoading: false,
          error: null,
          data: subOrdinateData || [],
        };
      },
      [rejected]: (state, action) => {
        state.subordinates = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }
}

const reportExtraReducers = createExtraReducers();
export default reportExtraReducers;
