import { createSlice } from "@reduxjs/toolkit";
import { RETAILER_MAPPING } from "../sliceConstants";
import extraReducers from "./thunk/retailerMappingExtraReducers";
import retailerMappingThunkActions from "./thunk/retailerMappingThunk";

const initialState = {
  retailerMappingHistory: {
    isLoading: false,
    data: [],
    error: [],
  },
};

export const retailerMappingFlowSlice = createSlice({
  name: RETAILER_MAPPING,
  initialState,
  reducers: {},
  extraReducers,
});

export const retailerActions = {
  ...retailerMappingFlowSlice.actions,
  ...retailerMappingThunkActions,
};

export default retailerMappingFlowSlice.reducer;
