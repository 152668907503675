import { createAsyncThunk } from "@reduxjs/toolkit";
import retailerMappingApi from "../../../../services/apiService/api/retailerMappingApi";
import { RETAILER_MAPPING } from "../../sliceConstants";

const retailerMappingThunkActions = {
  getRetailerMappingHistory: createAsyncThunk(
    `${RETAILER_MAPPING}/getRetailerMappingHistory`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await retailerMappingApi.getRetailerMappingHistory(body);
        return res?.data || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
};

export default retailerMappingThunkActions;
