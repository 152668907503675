
import retailerMappingThunkActions from "./retailerMappingThunk";

function createExtraReducers() {
  return {
    ...getRetailerMappingHistory(),
  };

  function getRetailerMappingHistory() {
    const { pending, fulfilled, rejected } =
    retailerMappingThunkActions.getRetailerMappingHistory;
    return {
      [pending]: (state) => {
        state.retailerMappingHistory.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.retailerMappingHistory = {
          isLoading: false,
          error: null,
          data: JSON.parse(action?.payload)?.data || [],
        };
      },
      [rejected]: (state, action) => {
        state.retailerMappingHistory = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
        };
      },
    };
  }
}

const retailerExtraReducers = createExtraReducers();
export default retailerExtraReducers;
